/** @jsx jsx */
import Logo from 'components/logo';
import { keyframes } from '@emotion/react';
import MobileDrawer from './mobile-drawer';
import { useStaticQuery, graphql } from 'gatsby';
import { jsx, Container, Flex, Button } from 'theme-ui';
import { DrawerProvider } from '../../contexts/drawer/drawer.provider';
// import { Link } from 'components/link';
import { Link as GatsbyLink } from 'gatsby';

import Divider from 'assets/divider.png';

export default function Header({ className }) {
  const data = useStaticQuery(graphql`
  {
    contentfulHeader {
      actionButton {
        name
        phoneNumber
      }
      logo {
        logoImage {
          title
          file {
            url
          }
        }
        logoName
      }
      navigationElement {
        name
        slug
      }
    }
  }
`)

const logo = data.contentfulHeader.logo.logoImage;
const button = data.contentfulHeader.actionButton;
const navItems = data.contentfulHeader.navigationElement;

  return (
    <DrawerProvider>
      <header sx={styles.header} className={className} id="header">
        <Container sx={styles.container}>
          <Logo src={logo.file.url} alt={logo.title} className="logo"/>
          <Flex as="nav" sx={styles.nav}>
            {navItems.map(({ name }, i) => (
              <GatsbyLink to="/" key={i} className="link">
                {name}
              </GatsbyLink>
            ))}
          </Flex>
          <Button
            as="a"
            className="donate__btn"
            variant="secondary"
            aria-label="Get Started"
            href={button.phoneNumber}
          >
           {button.name}
          </Button>
          <MobileDrawer />
        </Container>
      </header>
    </DrawerProvider>
  );
}

const positionAnim = keyframes`
  from {
    position: fixed;
    opacity: 1;
  }
  to {
    position: absolute;
    opacity: 1;
    transition: all 0.4s ease;
  }
`;
const styles = {
  header: {
    py: [4, null, 5],
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    fontFamily: 'heading',
    backgroundColor: 'transparent',
    transition: 'all 0.4s ease',
    animation: `${positionAnim} 0.4s ease`,
    '.logo':{
      width:'180px',
      height:'60px'
    },
    '.link':{
      textDecoration:'none'
    },
    '.donate__btn': {
      flexShrink: 0,
      mr: [15, 20, null, null, 0],
      ml: ['auto', null, null, null, 0],
      backgroundImage: ['none', null, null, null, `url(${Divider})`],
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      backgroundSize: 'contain',
      backgroundColor: [null, null, null, null, 'transparent'],
      '@media screen and (max-width: 320px)': {
        display: 'none',
      },
      '@media screen and (max-width: 768px)': {
        display: 'none',
      },
      color: 'primary',
      fontWeight: 'bold',
      py: ['12px', null, null, null, 2],
      px: [3, null, null, null, 0],
      ':hover': {
        backgroundColor: ['primary', null, null, null, 'transparent'],
        color: ['white', null, null, null, 'primary'],
      },
    },
    '&.sticky': {
      position: 'fixed',
      backgroundColor: 'background',
      color: 'text_secondary',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
      py: '20px',
      '.donate__btn': {
        border: '0px solid',
      },
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nav: {
    ml: 'auto',
    mr: 6,
    display: 'none',
    '@media screen and (min-width: 1024px)': {
      display: 'block',
    },
    a: {
      fontSize: '15px',
      fontWeight: 'body',
      px: 20,
      cursor: 'pointer',
      lineHeight: '1.2',
      transition: 'all 0.15s',
      color: '#343d48',
      '&.active': {
        color: 'primary',
      },
      ':hover': {
        color: 'primary',
      },
    },
  },
};
