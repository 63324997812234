   /** @jsx jsx */
   import { jsx, Box, Container,  Image, Heading, Grid, Flex} from 'theme-ui';
   import { Link } from 'gatsby'
   import Layout from 'components/subLayout';
   import { useStaticQuery, graphql } from 'gatsby';
   import SearchEngineOptimization from 'components/seo';
   import { StickyProvider } from '../contexts/app/app.provider';

   const ProductList = () => {
    const data = useStaticQuery(graphql`
    {
      contentfulProducts {
        productCards {
          slug
          name
          type
          image {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  `)
    const posts = data.contentfulProducts.productCards;

     return (
      <StickyProvider>
      <Layout mb={80}>
      <SearchEngineOptimization
        title={posts.name}
        description="This the product list page"
        />
        <Box id="productList" as="section" sx={styles.section}>
          <Container className="wrapper">
            <Heading sx={styles.title} variant="heroPrimary">Our Products</Heading>
            <Flex sx={styles.galleryWrapper}>
              <Grid columns={[1, 2, 2, 3, 3, 3]}>
                 {posts.map(({image, name, slug },) => (
                  <Link to={slug} key={slug} sx={styles.containerWrapper}>
                    <Image src={image.file.url} alt={image.file.fileName} loading="lazy"/>
                    <Box as="figcaption">{name}</Box>
                  </Link>  
                  ))}
                </Grid>
            </Flex>
          </Container>
        </Box>
      </Layout>
      </StickyProvider>
     );
   };
   
   export default ProductList;
 
   const styles = {
    section:{
     backgroundColor:'#fff',
     overflow: ['hidden', 'initial', null, null, 'hidden'],
     pt: ['150px', '200px'],
     pb:'50px',
     '.grid':{
       mt: '10px',
       gap: [20, 20, 20, 20, 30, 45],
    },
    },
    title: {
      color: 'heading',
      fontWeight: 'heading',
      fontSize: ['24px', '30px'],
      lineHeight: [1.2],
      letterSpacing: 'heading',
      textAlign: ['left', null, 'left', 'left'],
      '@media screen and (max-width: 768px)': {
        padding:'0'  
      },
    },
    containerWrapper:{
      position:'relative',
      textAlign: 'center',
      color:'#fff',
      ':before': {
        background: `linear-gradient(1.15deg, #020718 0.72%, rgba(255, 255, 255, 0) 41.15%)`,
        borderRadius: 5,
        opacity: 0.7,
        content: `''`,
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: 1,
      },
      img:{
        borderRadius: '7px',
        height:'100%',
        objectFit: 'cover',
        transform: 'scale(1)',
        transition: 'all 0.3s ease-in-out 0s',
        ':hover': {
          img: {
            transform: 'scale(1.2)',
          },
        },
      },
      figcaption:{
        position: 'absolute',
        bottom: '8px',
        left: '16px',
        color:'#fff',
        fontWeight: 'semi-bold',
        fontSize: 18,
        lineHeight: 1.68,
        letterSpacing: 'heading',
        zIndex: 1,

      },
    },
}